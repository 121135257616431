import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Add } from '@mui/icons-material';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableBody,
  TextField,
} from '@mui/material';
import { useConnectToCustomer, useCreateProviderToken, useInfinityGetMyCustomers } from 'api';
import { useIntersection } from 'hooks';
import { Modal } from 'components/ui-kit';
import { copyText } from 'utils';
import style from './page.module.scss';

const AddClientModal = ({ onClose }: { onClose: () => void }) => {
  const { trigger: connectToCustomer } = useConnectToCustomer();
  const [customerToken, setCustomerToken] = useState('');

  const handleConnect = async () => {
    try {
      await connectToCustomer({ customer_token: customerToken });
      toast.success('Клиент успешно добавлен');
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }

    onClose();
  };

  return (
    <Modal title='Добавление клиента' onClose={onClose}>
      <div className={style.cteateConnectModal}>
        <TextField
          label='Токен клиента'
          value={customerToken}
          onChange={e => setCustomerToken(e.currentTarget.value)}
        />
        <div className={style.buttonBlock}>
          <Button fullWidth color='error' onClick={onClose}>
            Закрыть
          </Button>
          <Button fullWidth onClick={handleConnect}>
            Добавить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const Clinets = () => {
  const { trigger: createToken } = useCreateProviderToken();
  const { data: clientsData, setSize } = useInfinityGetMyCustomers({ page_size: 20 });
  const [token, setToken] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLTableRowElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: containerRef.current });

  const clients = useMemo(() => {
    if (!clientsData) return [];
    return clientsData.flatMap(page => page.results);
  }, [clientsData]);

  useEffect(() => {
    if (!isVisible || !clientsData || !clientsData[clientsData.length - 1]?.next) return;
    setSize(state => ++state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleCreateToken = async () => {
    try {
      const res = await createToken({ is_active: true });
      setToken(res.token);
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  return (
    <>
      <div className={style.tokenBlock}>
        {token ? (
          <TextField className={style.tokenInput} value={token} aria-readonly onClick={() => copyText(token)} />
        ) : (
          <Button className={style.tokenButton} onClick={handleCreateToken}>
            Показать мой токен для клиента (я — исполнитель)
          </Button>
        )}
      </div>
      <Button className={style.addUserButton} startIcon={<Add />} onClick={() => setIsOpenModal(true)}>
        Добавить клиента
      </Button>
      <TableContainer className={style.table} component={Paper} ref={containerRef}>
        <Table sx={{ minWidth: 1200 }}>
          <TableHead>
            <TableRow>
              <TableCell>Компания</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>Эл. почта</TableCell>
              <TableCell>Кол-во обращений</TableCell>
              <TableCell>Последнее обращение</TableCell>
              <TableCell>Соединение с клиентом</TableCell>
              {/* <TableCell>Токен клиента</TableCell>
              <TableCell>Мой токен для клиента</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map(({ id, is_active, customer_details: { company_name, email, phone } }, index) => {
              const lastIndex = clients.length - 1;
              const isLastItem = index === lastIndex;

              return (
                <TableRow
                  key={id}
                  className={!is_active ? style.notActive : ''}
                  ref={isLastItem ? lastItemRef : undefined}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{company_name}</TableCell>
                  <TableCell>{phone}</TableCell>
                  <TableCell>{email}</TableCell>
                  {/* TODO: бэк должен добавить счетчик ображений */}
                  <TableCell>{'count'}</TableCell>
                  {/* TODO: бэк должен отдавать дату последнего обращения */}
                  <TableCell>{'lastDate'}</TableCell>
                  <TableCell>{is_active ? 'Активное' : 'Ждет подтверждения'}</TableCell>
                  {/* TODO: наверное должна быть возможность разорвать соединение */}
                  {/* <TableCell>
                    <Button size='small'>Внести</Button>
                  </TableCell>
                  <TableCell>
                    <Button size='small'>Внести</Button>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isOpenModal && <AddClientModal onClose={() => setIsOpenModal(false)} />}
    </>
  );
};
