import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useGetColleagues } from 'api/endpoints/user/get-colleagues';
import { Modal } from 'components/ui-kit';
import { User } from 'types';
import style from './select-members-modal.module.scss';

type SelectMembersModalProps = {
  onClose: () => void;
  onSave: (selectMembers: User[]) => void;
};
export const SelectMembersModal = ({ onClose, onSave }: SelectMembersModalProps) => {
  //TODO: сделать, чтобы в эндпоинте добавился поиск
  const { data: colleaguesData } = useGetColleagues();

  //const [search, setSearch] = useState('');
  const [selectMembers, setSelectMembers] = useState<User[]>([]);

  return (
    /* TODO: Возможно модалка и компонент будут называться подругому */
    <Modal title='Выберите участников' onClose={onClose}>
      <div className={style.content}>
        {/* <TextField
          label='Поиск сотрудника'
          fullWidth
          type='search'
          value={search}
          onChange={e => setSearch(e.currentTarget.value)}
        /> */}
        <div className={style.members}>
          {colleaguesData?.map(item => (
            <div
              key={item.id}
              onClick={() =>
                setSelectMembers(state => {
                  const has = state.some(stateItem => stateItem.id === item.id);
                  return has ? state : [...state, item];
                })
              }
            >
              {item.username}
            </div>
          ))}
        </div>
        {!!selectMembers.length && (
          <>
            <div>Выбранные участнки</div>
            <div className={style.selectMembers}>
              {selectMembers.map(item => (
                <div
                  key={item.id}
                  onClick={() => setSelectMembers(state => state.filter(selectMember => selectMember.id !== item.id))}
                >
                  <span>{item.username}</span>
                  <Close className={style.icon} fontSize='inherit' />
                </div>
              ))}
            </div>
          </>
        )}
        <div className={style.buttons}>
          <Button color='error' onClick={onClose}>
            Закрыть
          </Button>
          <Button
            variant='contained'
            color='success'
            disabled={!selectMembers.length}
            onClick={() => onSave(selectMembers)}
          >
            Добавить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
