import { fetcher, Method, MEETINGS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

type AddUsersParams = {
  id: string | number;
  body: {
    user_ids: string[];
  };
};

type AddUsersResponse = { user_ids: string[] };

const addUsers = async (path: string, { arg: { id, body } }: { arg: AddUsersParams }) =>
  fetcher<AddUsersResponse>({ path: `${path}${id}/add_participants_bulk/`, method: Method.POST, body });

export const useAddUsers = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(MEETINGS, addUsers, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(MEETINGS));
    },
  });
};
