import { toast } from 'react-toastify';

export const copyText = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Успешно скопировано');
  } catch (e) {
    console.error(e);
  }
};
