import React, { FC } from 'react';
import { InputRange } from 'components/ui-kit';
import style from './tools.module.scss';

const COLORS = [
  {
    color: 'red',
  },
  {
    color: 'blue',
  },
  {
    color: 'black',
  },
  {
    color: 'green',
  },
  {
    color: 'pink',
  },
] as const;

type ToolsBlockProps = {
  size: number;
  color: string;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
};

export const ToolsBlock: FC<ToolsBlockProps> = ({ size, color, setSize, setColor }) => {
  //const { t } = useTranslation('translation', { keyPrefix: 'modals.edit-snapshot-modal' });

  const handleChangeColor = (color: string) => {
    setColor(color);
  };

  return (
    <div className={style.block}>
      <div className={style.previewBlock}>
        <div className={style.preview} style={{ width: size, height: size, background: color }}></div>
      </div>
      <InputRange id='size' label='Размер' min={1} max={50} value={size} onChange={setSize} />
      {COLORS.map(({ color }) => (
        <div
          key={color}
          className={style.color}
          style={{ background: color, borderColor: color }}
          onClick={() => handleChangeColor(color)}
        ></div>
      ))}
    </div>
  );
};
