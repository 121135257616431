import React from 'react';
import { useGetUserRole } from 'hooks/use-get-user-role';
import { HomeAdmin } from 'pages/home-admin';
import { HomeDispatcher } from 'pages/home-dispatcher';
import { HomeEngineer } from 'pages/home-engineer';

export const PrivateCabinet = () => {
  const role = useGetUserRole();
  return (
    <>
      {role === 'admin' && <HomeAdmin />}
      {role === 'dispatcher' && <HomeDispatcher />}
      {role === 'engineer' && <HomeEngineer />}
    </>
  );
};
