import React, { FC, ReactNode } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { Button } from 'components/ui-kit/button';
import style from './private-cabinet-header.module.scss';

type PrivateCabinetHeaderProps = {
  title: string;
  children?: ReactNode;
  onBack?: () => void;
};

export const PrivateCabinetHeader: FC<PrivateCabinetHeaderProps> = ({ title, children, onBack }) => {
  return (
    <div className={style.header}>
      {onBack && <Button classNames={{ button: style.button }} label={<FaArrowLeft size='20' />} onClick={onBack} />}
      <div className={style.title}>{title}</div>
      {children}
    </div>
  );
};
