import { fetcher, Method, PROVIDER_TOKENS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export type CreateProviderTokenParams = {
  is_active: boolean;
};

export type CreateProviderTokenResponse = {
  id: string;
  token: string;
  is_active: boolean;
  created_at: string;
};

const createProviderToken = async (path: string, { arg }: { arg: CreateProviderTokenParams }) =>
  fetcher<CreateProviderTokenResponse>({ path, method: Method.POST, body: arg });

export const useCreateProviderToken = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(PROVIDER_TOKENS, createProviderToken, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(PROVIDER_TOKENS));
    },
  });
};
