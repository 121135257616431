import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaVideo } from 'react-icons/fa6';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { PiWarningCircleFill } from 'react-icons/pi';
import cn from 'classnames';
import { Tooltip } from 'components/ui-kit';
import style from './stream-options.module.scss';

type StreamOptionsProps = {
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  setIsActiveMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveVideo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StreamOptions: FC<StreamOptionsProps> = ({
  isActiveMicrophone,
  isActiveVideo,
  isHasAccessMicrophone,
  isHasAccessVideo,
  setIsActiveMicrophone,
  setIsActiveVideo,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const handleToggleMicrophone = () => setIsActiveMicrophone(state => !state);

  const handleToggleVideo = () => setIsActiveVideo(state => !state);

  return (
    <div className={style.block}>
      <Tooltip id='streamToggleMic' content={t('toggleMic', { status: isActiveMicrophone ? t('off') : t('on') })} />
      <button
        className={cn(style.button, { [style.button__off]: !isActiveMicrophone || !isHasAccessMicrophone })}
        data-tooltip-id='streamToggleMic'
        disabled={!isHasAccessMicrophone}
        onClick={handleToggleMicrophone}
      >
        {!isHasAccessMicrophone && (
          <>
            <Tooltip id='streanMicNotFound' content={t('micNotFound')} />
            <PiWarningCircleFill
              className={style.warning}
              color='#facc15'
              size='20px'
              data-tooltip-id='streanMicNotFound'
            />
          </>
        )}
        <HiOutlineMicrophone size='20px' color='black' />
      </button>
      <Tooltip id='streamToggleVideo' content={t('toggleVideo', { status: isActiveMicrophone ? t('off') : t('on') })} />
      <button
        className={cn(style.button, { [style.button__off]: !isActiveVideo || !isHasAccessVideo })}
        data-tooltip-id='streamToggleVideo'
        disabled={!isHasAccessVideo}
        onClick={handleToggleVideo}
      >
        {!isHasAccessVideo && (
          <>
            <Tooltip id='streamVideoNotFound' content={t('videoNotFound')} />
            <PiWarningCircleFill
              className={style.warning}
              color='#facc15'
              size='20px'
              data-tooltip-id='streamVideoNotFound'
            />
          </>
        )}
        <FaVideo size='20px' color='black' />
      </button>
    </div>
  );
};
