import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetMe } from 'api';
import { PrivateCabinetHeader } from 'components/headers';
import { Tabs } from 'components/ui-kit';
import { QUERY } from 'constants/path';
import { ActiveConference } from './active-conference';
//import { ArchiveConference } from './archive-conference';
import { Clinets } from './clinets';
import { UsersColumn, HeadsetsColumn } from './components';
import { Executor } from './executor';
import style from './page.module.scss';

const TABS_DATA = [
  { id: 'headsets_users', label: 'Гарнитуры и пользователи' },
  { id: 'active', label: 'Активные встречи' },
  /* TODO: везде добавить подтягивание архивных встреч */
  /* TODO: раскомментить */
  /* { id: 'archive', label: 'Архив встреч' }, */
  { id: 'clients', label: 'Клиенты ТП' },
  { id: 'executor', label: 'Исполнители ТП' },
];

const HeadsetsAndUsers = () => {
  const { data: user } = useGetMe();
  const isAdmin = user && !user.parent;

  return (
    <div className={style.content}>
      <HeadsetsColumn />
      {isAdmin && <UsersColumn />}
    </div>
  );
};

export const HomeAdmin = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const [searchParams, setSearchParams] = useSearchParams();
  const pageType = searchParams.get(QUERY.type) ?? TABS_DATA[0].id;
  const handleChangeType = (activeId: string) => setSearchParams({ [QUERY.type]: activeId });

  return (
    <>
      <PrivateCabinetHeader title={t('title')} />
      <div className={style.container}>
        <Tabs data={TABS_DATA} activeId={pageType} onChange={handleChangeType} />
        {pageType === 'headsets_users' && <HeadsetsAndUsers />}
        {pageType === 'active' && <ActiveConference />}
        {/* TODO: раскомментить */}
        {/* {pageType === 'archive' && <ArchiveConference />} */}
        {pageType === 'clients' && <Clinets />}
        {pageType === 'executor' && <Executor />}
      </div>
    </>
  );
};
