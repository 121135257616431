import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowUp } from 'react-icons/fa';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { Tooltip } from 'components/ui-kit';
import style from './controls.module.scss';

const STYLE = {
  left: { transform: 270, bottom: 10, left: 3 },
  up: { transform: 0, bottom: 20, left: 20 },
  right: { transform: 90, bottom: 10, left: 37 },
  down: { transform: 180, bottom: 0, left: 20 },
};

type RecordProps = {
  socket: Socket | null;
  id: keyof typeof STYLE;
};

export const Arrow: FC<RecordProps> = ({ socket, id }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleMouseDown = () => {
    if (!socket) return;
    socket.emit(id, true);
  };
  const handleMouseUp = () => {
    if (!socket) return;
    socket.emit(id, false);
  };

  const { transform, left, bottom } = STYLE[id];

  return (
    <>
      <Tooltip id={`controls.arrow.${id}`} content={t(`controls.arrow.${id}`)} />
      <button
        className={cn(style.button, style.button__arrow)}
        style={{ transform: `rotate(${transform}deg)`, left, bottom }}
        data-tooltip-id={`controls.arrow.${id}`}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <FaArrowUp size='15px' color='black' />
      </button>
    </>
  );
};
