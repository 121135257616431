import { Method, MY_PROVIDERS, fetcher } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

type GetMyProvidersParams = {
  page?: number;
  page_size?: number;
};

export type GetMyProvidersResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: string;
    customer: string;
    provider: string;
    customer_details: {
      id: string;
      role: string;
      username: string;
      parent: string;
      email: string;
      company_name: string;
      phone: string;
      is_active: boolean;
      is_verify: boolean;
      headset: string;
    };
    provider_details: {
      id: string;
      role: string;
      username: string;
      parent: string;
      email: string;
      company_name: string;
      phone: string;
      is_active: boolean;
      is_verify: boolean;
      headset: string;
    };
    is_active: boolean;
  }[];
};

const getMyProviders = (path: string) => fetcher<GetMyProvidersResponse>({ path, method: Method.GET });

export const useGetMyProviders = (params: GetMyProvidersParams) => {
  //@ts-ignore
  const query = new URLSearchParams(params);
  return useSWR(`${MY_PROVIDERS}?${query}`, getMyProviders);
};

export const useInfinityGetMyProviders = (params: Omit<GetMyProvidersParams, 'offset'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const page = pageIndex + 1;
    //@ts-ignore
    const query = new URLSearchParams({ ...params, page });
    return `${MY_PROVIDERS}?${query}`;
  }, getMyProviders);
