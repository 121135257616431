import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useUpdateSnapshot } from 'api';
import { Modal, Button } from 'components/ui-kit';
import style from './edit-snapshot-modal.module.scss';
import { ToolsBlock } from './tools';

type EditSnapshotModalProps = {
  src: string;
  href: string;
  onClose: () => void;
};

export const EditSnapshotModal: FC<EditSnapshotModalProps> = ({ src, href, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.edit-snapshot-modal' });
  const { trigger: updateSnapshot } = useUpdateSnapshot();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const [size, setSize] = useState(2);
  const [color, setColor] = useState('red');

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    const { current: canvas } = canvasRef;
    const { current: image } = imageRef;
    if (!canvas || !image) return;

    const ctx = canvas.getContext('2d');
    setCtx(ctx);
    if (!ctx) return;

    const imageWidth = image.width;
    const imageHeight = image.height;
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
    image.style.display = 'none';

    return () => {
      image.style.display = 'block';
    };
  }, [isFirstRender]);

  const handleStartDrawing: React.MouseEventHandler<HTMLCanvasElement> = e => {
    if (!ctx) return;

    const { offsetX, offsetY } = e.nativeEvent;
    setIsDrawing(true);
    ctx.beginPath();
    ctx.lineWidth = size;
    ctx.strokeStyle = color;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.moveTo(offsetX, offsetY);
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const handleFinishDrawing: React.MouseEventHandler<HTMLCanvasElement> = e => {
    if (!ctx) return;

    setIsDrawing(false);
    ctx.closePath();
  };

  const handleMouseMove: React.MouseEventHandler<HTMLCanvasElement> = e => {
    if (!isDrawing || !ctx) return;

    const { offsetX, offsetY } = e.nativeEvent;
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const handleSave = async () => {
    const { current } = canvasRef;
    if (!current) return;

    const hrefArr = href.split('/');
    const hrefLenght = hrefArr.length;
    const id = hrefArr[hrefLenght - 2];

    current.toBlob(async blob => {
      try {
        if (!blob) return;

        const formData = new FormData();
        const file = new File([blob], 'blob.jpeg', { type: 'image/jpeg' });
        formData.append('data', file);

        await updateSnapshot({ id, formData });
        toast.success(t('successMessade'));
        onClose();
      } catch (e) {
        toast.error(t('errorMessade', { error: (e as { message: string }).message }));
      }
    }, 'image/jpeg');
  };

  return (
    <Modal classNames={{ modal: style.modal }} title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <div className={style.photo}>
          <img className={style.image} ref={imageRef} src={src} alt='' />
          <canvas
            ref={canvasRef}
            onMouseDown={handleStartDrawing}
            onMouseUp={handleFinishDrawing}
            onMouseLeave={handleFinishDrawing}
            onMouseMove={handleMouseMove}
          />
        </div>
        <ToolsBlock size={size} color={color} setColor={setColor} setSize={setSize} />
      </div>
      <div className={style.buttons}>
        <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
        <Button classNames={{ button: style.button }} label={t('save')} onClick={handleSave} />
      </div>
    </Modal>
  );
};
