import React, { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import { Notification } from 'components/ui-kit';

type LoadingNotificationProps = {
  socket: Socket | null;
};
export const LoadingNotification = ({ socket }: LoadingNotificationProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!socket) return;

    socket.on('start_snapshot', () => setIsVisible(true));
    socket.on('end_snapshot', () => setIsVisible(false));

    return () => {
      socket.off('start_snapshot');
      socket.off('end_snapshot');
    };
  }, [socket]);

  const onClose = () => setIsVisible(false);

  return isVisible ? (
    <Notification onClose={onClose}>
      <div>Загрузка...</div>
    </Notification>
  ) : null;
};
