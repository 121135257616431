import { useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfinityGetHeadsets, useGetMe } from 'api';
import { useIntersection } from 'hooks';
import { Column } from 'components/column';
import { AddHeadsetModal } from 'components/modals';
import { HeadsetsColumnItem } from './';

const LIMIT = 20;

export const HeadsetsColumn = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const { data: headsetsData, setSize } = useInfinityGetHeadsets({ limit: LIMIT });
  const { data: user } = useGetMe();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const columnRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: columnRef.current });

  const headsets = useMemo(() => {
    if (!headsetsData) return [];
    return headsetsData.flatMap(page => page.results);
  }, [headsetsData]);

  useEffect(() => {
    if (!isVisible || !headsetsData || !headsetsData[headsetsData.length - 1]?.next) return;
    setSize(state => ++state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleAddHeadset = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const displayList = useMemo(
    () =>
      headsets.map((item, index) => (
        <HeadsetsColumnItem
          key={item.id}
          headsetData={item}
          meData={user}
          columnItemRef={index === headsets.length - 1 ? lastItemRef : undefined}
        />
      )),
    [headsets, user],
  );

  const isAdmin = !user?.parent;

  return (
    <>
      <Column
        title={t('headsetsTitle')}
        buttonLabel={t('addHeadsetButton')}
        columnRef={columnRef}
        isDisabled={!user?.is_verify}
        isHiddenButton={!isAdmin}
        onClick={handleAddHeadset}
      >
        {displayList}
      </Column>
      {isOpenModal && <AddHeadsetModal onClose={handleCloseModal} />}
    </>
  );
};
