import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthHeader } from 'components/headers';
import { Button } from 'components/ui-kit';
import { LOGIN, REGISTRATION } from 'constants/path';
import style from './auth.module.scss';

export const Auth = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate(LOGIN);
  };

  const handleClickRegistration = () => {
    navigate(REGISTRATION);
  };

  return (
    <>
      <AuthHeader title={t('authorization')} />
      <Button classNames={{ button: style.button }} label={t('login')} onClick={handleClickLogin} />
      <Button classNames={{ button: style.button }} label={t('registration')} onClick={handleClickRegistration} />
    </>
  );
};
