import React from 'react';
import { Button } from '@mui/material';
import { GetMyProvidersResponse, useGetMyProviders } from 'api/endpoints/customer-provider/get-providers';
import { Modal } from 'components/ui-kit';
import style from './providers-modal.module.scss';

type Connection = GetMyProvidersResponse['results'][0] | null;

type ProvidersModalProps = {
  setSelectedConnection: React.Dispatch<React.SetStateAction<Connection>>;
  onClose: () => void;
};
export const ProvidersModal = ({ setSelectedConnection, onClose }: ProvidersModalProps) => {
  /* TODO: добавить пагинацию */
  const { data } = useGetMyProviders({ page: 1, page_size: 20 });

  const selectConnection = (connection: Connection) => {
    setSelectedConnection(connection);
    onClose();
  };

  return (
    <Modal title='Выберите исполнителя' onClose={onClose}>
      <div className={style.content}>
        {data?.results.map(item => (
          <div key={item.id} className={style.item} onClick={() => selectConnection(item)}>
            {item.provider_details.company_name}
          </div>
        ))}
      </div>
      <div className={style.buttons}>
        <Button fullWidth color='error' onClick={onClose}>
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};
