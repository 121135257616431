import React, { useMemo } from 'react';
import { useGetMe } from 'api';
import { User } from 'types';

type ConferenceUsersTableBlockProps = {
  users: User[];
};
export const ConferenceUsersTableBlock = ({ users }: ConferenceUsersTableBlockProps) => {
  const { data: dataMe } = useGetMe();

  const [myUsers, otherUsers] = useMemo(() => {
    if (!dataMe) return [[], []];
    const myParentId = dataMe.parent?.id ?? dataMe.id;
    const myUsers: User[] = [];
    const otherUsers: User[] = [];

    users.forEach(item => {
      const parentId = item.parent?.id ?? item.id;
      if (parentId === myParentId) {
        myUsers.push(item);
      } else {
        otherUsers.push(item);
      }
    });

    return [myUsers, otherUsers];
  }, [dataMe, users]);

  return (
    <div>
      <div>
        <b>С моей стороны</b>
      </div>
      <div>{myUsers.map(({ username }) => username).join(', ') || '--'}</div>
      <div>
        <b>Другие</b>
      </div>
      <div>{otherUsers.map(({ username }) => username).join(', ') || '--'}</div>
    </div>
  );
};
