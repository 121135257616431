import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import dateAndTime from 'date-and-time';
import { Modal, Button, Input, Checkbox } from 'components/ui-kit';
import style from './check-list-modal.module.scss';

type CheckListItem = {
  id: number;
  text: string;
  time: string;
  isChecked: boolean;
};

type CheckItemProps = CheckListItem & {
  number: number;
  setCheckList: React.Dispatch<React.SetStateAction<CheckListItem[]>>;
};

const CheckItem: FC<CheckItemProps> = ({ id, text, time, isChecked, number, setCheckList }) => {
  const handleChangeTime = (time: string) => {
    setCheckList(state => state.map(item => (item.id === id ? { ...item, time } : item)));
  };

  const handleToggleChecked = (checked: boolean) => {
    const date = new Date();
    const time = dateAndTime.format(date, 'HH:mm');

    setCheckList(state => state.map(item => (item.id === id ? { ...item, isChecked: checked, time } : item)));
  };

  return (
    <div className={style.item}>
      {isChecked ? (
        <Input classNames={{ block: style.inputBlock, input: style.input }} value={time} onChange={handleChangeTime} />
      ) : (
        <div className={style.inputBlock__fake}></div>
      )}
      <Checkbox id={`checkbox_${id}`} isChecked={isChecked} onChange={handleToggleChecked} />
      <div className={style.text}>
        {number}. {text}
      </div>
    </div>
  );
};

type CheckListModalProps = {
  title: string;
  checkList: CheckListItem[];
  setCheckList: React.Dispatch<React.SetStateAction<CheckListItem[]>>;
  onClose: () => void;
};

export const CheckListModal: FC<CheckListModalProps> = ({ title, checkList, setCheckList, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.check-list-modal' });

  const displayContent = useMemo(
    () =>
      checkList.map(({ id, ...props }, index) => (
        <CheckItem key={id} number={index + 1} id={id} setCheckList={setCheckList} {...props} />
      )),
    [checkList, setCheckList],
  );

  return (
    <Modal classNames={{ modal: style.modal }} title={title} onClose={onClose}>
      <div className={style.content}>
        <div className={style.list}>{displayContent}</div>
      </div>
      <div className={style.buttons}>
        <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
      </div>
    </Modal>
  );
};
