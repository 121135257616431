import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPeopleFill } from 'react-icons/bs';
import cn from 'classnames';
import { OtherContent } from 'pages/meeting/constants';
import { Tooltip } from 'components/ui-kit';
import style from './controls.module.scss';

type PeopleProps = {
  typeOtherContent: OtherContent | null;
  setIsTypeOtherContent: React.Dispatch<React.SetStateAction<OtherContent | null>>;
};

export const People: FC<PeopleProps> = ({ typeOtherContent, setIsTypeOtherContent }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleOpenPeople = () => {
    setIsTypeOtherContent(state => (state === OtherContent.People ? null : OtherContent.People));
  };

  return (
    <>
      <Tooltip id='controls.people' content={t('controls.people')} />
      <button
        className={cn(style.button, { [style.button__off]: typeOtherContent !== OtherContent.People })}
        data-tooltip-id='controls.people'
        onClick={handleToggleOpenPeople}
      >
        <BsPeopleFill size='25px' color='black' />
      </button>
    </>
  );
};
