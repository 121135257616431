import React from 'react';
import cn from 'classnames';
import { TypeChoice } from 'types';
import style from './conference-time-type.module.scss';

const TYPE_NAME = {
  now: 'Горит - сейчас!',
  today: 'Сегодня',
  three_days: 'В течении трех дней',
  custom: 'Свободная дата',
};

type ConferenceTimeTypeProps = {
  type: TypeChoice;
};
export const ConferenceTimeType = ({ type }: ConferenceTimeTypeProps) => {
  return (
    <div className={style.container}>
      <div
        className={cn(style.type, {
          [style.type__now]: type === 'now',
          [style.type__today]: type === 'today',
          [style.type__threeDays]: type === 'three_days',
          [style.type__custom]: type === 'custom',
        })}
      >
        {TYPE_NAME[type]}
      </div>
    </div>
  );
};
