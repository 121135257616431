import { fetcher, Method, CUSTOMET_TOKENS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export type CreateCustomerTokenParams = {
  is_active: boolean;
};

export type CreateCustomerTokenResponse = {
  id: string;
  token: string;
  is_active: boolean;
  created_at: string;
};

const createCustomerToken = async (path: string, { arg }: { arg: CreateCustomerTokenParams }) =>
  fetcher<CreateCustomerTokenResponse>({ path, method: Method.POST, body: arg });

export const useCreateCustomerToken = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(CUSTOMET_TOKENS, createCustomerToken, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(CUSTOMET_TOKENS));
    },
  });
};
