import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useDeleteHeadset } from 'api';
import { Modal, Button } from 'components/ui-kit';
import { Headset } from 'types';
import style from './delete-headset-modal.module.scss';

type DeleteHeadsetModalProps = {
  headsetData: Headset;
  onClose: () => void;
};

export const DeleteHeadsetModal: FC<DeleteHeadsetModalProps> = ({ headsetData, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.delete-headset-modal' });
  const { trigger: deleteHeadset } = useDeleteHeadset();
  const { id, name } = headsetData;

  const handleDeleteHeadset = async () => {
    try {
      await deleteHeadset({ id });
      onClose();
      toast.success(t('successMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  return (
    <Modal title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <div className={style.item}>{t('discription', { name })}</div>
        <div className={style.buttons}>
          <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
          <Button classNames={{ button: style.button }} label={t('delete')} onClick={handleDeleteHeadset} />
        </div>
      </div>
    </Modal>
  );
};
