import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui-kit';
import { CompleteBlock, QualityExpertise, PleasantCommunication, CommunicationQuality } from './blocks';
import style from './survey.module.scss';

type SurvayProps = {
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Survay: FC<SurvayProps> = ({ setIsSubmit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'finish_meeting' });
  const [completeValue, setCompleteValue] = useState('');
  const [completeComment, setCompleteComment] = useState('');

  const [qualityExpertiseValue, setQualityExpertiseValue] = useState('');
  const [qualityExpertiseComment, setQualityExpertiseComment] = useState('');

  const [pleasantCommunicationValue, setPleasantCommunicationValue] = useState('');
  const [pleasantCommunicationComment, setPleasantCommunicationComment] = useState('');

  const [communicationQualityValue, setCommunicationQualityValue] = useState('');
  const [communicationQualityComment, setCommunicationQualityComment] = useState('');

  const handleSubmit = () => {
    setIsSubmit(true);
  };

  const isDisabled = !(
    completeValue &&
    qualityExpertiseValue &&
    pleasantCommunicationValue &&
    communicationQualityValue
  );

  return (
    <div className={style.container}>
      <div className={style.blocks}>
        <div className={style.scrollBlocks}>
          <CompleteBlock
            value={completeValue}
            comment={completeComment}
            setValue={setCompleteValue}
            setComment={setCompleteComment}
          />
          <QualityExpertise
            value={qualityExpertiseValue}
            comment={qualityExpertiseComment}
            setValue={setQualityExpertiseValue}
            setComment={setQualityExpertiseComment}
          />
          <PleasantCommunication
            value={pleasantCommunicationValue}
            comment={pleasantCommunicationComment}
            setValue={setPleasantCommunicationValue}
            setComment={setPleasantCommunicationComment}
          />
          <CommunicationQuality
            value={communicationQualityValue}
            comment={communicationQualityComment}
            setValue={setCommunicationQualityValue}
            setComment={setCommunicationQualityComment}
          />
        </div>
      </div>
      <Button classNames={{ button: style.button }} label={t('next')} isDisabled={isDisabled} onClick={handleSubmit} />
    </div>
  );
};
