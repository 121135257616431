import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRegistrationUser } from 'api';
import { AuthHeader } from 'components/headers';
import { Input, Button } from 'components/ui-kit';
import { AUTH } from 'constants/path';
import style from './registration.module.scss';

export const Registration = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'registration' });
  const navigate = useNavigate();
  const { trigger: registrationUser } = useRegistrationUser();
  const [login, setLogin] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [componyName, setComponyName] = useState('');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    try {
      const body = {
        username: login,
        phone,
        email,
        company_name: componyName,
      };
      await registrationUser(body);
      toast.success(t('successMessage', { email }));
      navigate(AUTH);
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handleBack = () => {
    navigate(AUTH);
  };

  const isDisabled = !(login && phone && email && componyName);

  return (
    <>
      <AuthHeader title={t('title')} onBack={handleBack} />
      <form onSubmit={handleSubmit}>
        <Input
          classNames={{ block: style.input }}
          label={t('loginLabel')}
          placeholder={t('loginPlaceholder')}
          value={login}
          onChange={setLogin}
        />
        <Input
          classNames={{ block: style.input }}
          type='phone'
          label={t('phoneLabel')}
          placeholder={t('phonePlaceholder')}
          value={phone}
          onChange={setPhone}
        />
        <Input
          classNames={{ block: style.input }}
          type='email'
          label={t('emailLabel')}
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={setEmail}
        />
        <Input
          classNames={{ block: style.input }}
          label={t('componyNameLabel')}
          placeholder={t('componyNamePlaceholder')}
          value={componyName}
          onChange={setComponyName}
        />
        <Button classNames={{ button: style.button }} isDisabled={isDisabled} label={t('registrationButton')} />
      </form>
    </>
  );
};
