import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeleteVideo } from 'api';
import { GetVideoResponce } from 'api/endpoints/video/get-video';
import { VideoModal } from 'components/modals';
import { Tooltip } from 'components/ui-kit';
import { VisibleIcon, DeleteIcon } from 'assets';
import style from '../headset-details.module.scss';

type HeadsetVideoItemProps = {
  video: GetVideoResponce['results'][0];
  columnItemRef?: React.RefObject<HTMLDivElement>;
  setVideo: React.Dispatch<React.SetStateAction<GetVideoResponce['results']>>;
};

export const HeadsetVideoItem: FC<HeadsetVideoItemProps> = ({ video, columnItemRef, setVideo }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'headset_details' });
  const { trigger: deleteVideo } = useDeleteVideo();
  const { created_at: title, url, id } = video;
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteVideo({ id });
      setVideo(state => state.filter(item => item.id !== id));
      toast.success('Видео успешно удалено');
    } catch (e) {
      toast.error('При удалении видео произошла ошибка');
      console.error(e);
    }
  };

  const handleOpenVideoModal = () => setIsOpenVideoModal(true);
  const handleCloseVideoModal = () => setIsOpenVideoModal(false);

  return (
    <>
      <div className={style.columnItem} ref={columnItemRef}>
        <div className={style.point}></div>
        <div className={style.name}>{title}</div>
        <div className={style.buttons}>
          <Tooltip id={'visible_button_title_video' + id} content={t('visible_button_title')} />
          <button
            className={style.button}
            data-tooltip-id={'visible_button_title_video' + id}
            onClick={handleOpenVideoModal}
          >
            <VisibleIcon width='20px' height='20px' />
          </button>
          <Tooltip id={'delete_button_title_video' + id} content={t('delete_button_title')} />
          <button className={style.button} data-tooltip-id={'delete_button_title_video' + id} onClick={handleDelete}>
            <DeleteIcon width='20px' height='20px' />
          </button>
        </div>
      </div>
      {isOpenVideoModal && <VideoModal url={url} onClose={handleCloseVideoModal} />}
    </>
  );
};
