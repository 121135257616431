import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { Tooltip } from 'components/ui-kit';
import style from './controls.module.scss';

type SnapshotProps = {
  socket: Socket | null;
};

export const Snapshot: FC<SnapshotProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleClick = () => {
    if (!socket) return;
    socket.emit('snapshot');
  };

  return (
    <>
      <Tooltip id='controls.snapshot' content={t('controls.snapshot')} />
      <button
        className={cn(style.button, style.button__snapshot)}
        data-tooltip-id='controls.snapshot'
        onClick={handleClick}
      >
        Snapshot
      </button>
    </>
  );
};
