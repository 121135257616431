import { LOCAL_STORAGE_USER_ROLE } from 'constants/local-storage';

export const getUserRole = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_USER_ROLE);
  return token;
};

export const setUserRole = (role: string) => {
  localStorage.setItem(LOCAL_STORAGE_USER_ROLE, role);
};

export const removeUserRole = () => {
  localStorage.removeItem(LOCAL_STORAGE_USER_ROLE);
};
