import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowsAltH } from 'react-icons/fa';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { Tooltip } from 'components/ui-kit';
import style from './controls.module.scss';

type VideoFurtherProps = {
  socket: Socket | null;
};

export const VideoFurther: FC<VideoFurtherProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isTof, setIsTof] = useState(false);

  useEffect(() => {
    if (!socket) return;
    const updateState = (value: boolean) => {
      setIsTof(value);
    };
    socket.on('tof', updateState);

    return () => {
      socket.off('tof', updateState);
    };
  }, [socket]);

  const handleToggle = () => {
    socket?.emit('tof', !isTof);
    setIsTof(!isTof);
  };

  return (
    <>
      <Tooltip
        id='controls.videoFurther'
        content={t('controls.videoFurther', { status: isTof ? t('off') : t('on') })}
      />
      <button
        className={cn(style.button, { [style.button__off]: !isTof })}
        data-tooltip-id='controls.videoFurther'
        onClick={handleToggle}
      >
        <FaArrowsAltH size='20px' color='black' />
      </button>
    </>
  );
};
