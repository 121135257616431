import { useEffect, useState } from 'react';
import { useGetMe } from 'api';
import { getUserRole, setUserRole } from 'utils/user-role';

export const useGetUserRole = () => {
  const { data } = useGetMe();
  const [role, setRole] = useState('');

  useEffect(() => {
    const role = getUserRole();
    if (!role && data) {
      setUserRole(data.role);
      setRole(data.role);
    } else if (role) {
      setRole(role);
    }
  }, [data]);

  return role;
};
