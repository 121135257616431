import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useUpdateUserPassword } from 'api';
import { UpdateUserPasswordModal } from 'components/modals';
import { Button, Modal } from 'components/ui-kit';
import { User } from 'types';
import style from './confirm-update-user-password-modal.module.scss';

type ConfirmUpdateUserPasswordModalProps = {
  userData: User;
  onClose: () => void;
};

export const ConfirmUpdateUserPasswordModal: FC<ConfirmUpdateUserPasswordModalProps> = ({ userData, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.confirm-update-user-password-modal' });
  const { t: tUpdateUserPasswordModal } = useTranslation('translation', {
    keyPrefix: 'modals.update-user-password-modal',
  });
  const { isMutating, data: newUserData, trigger: updateUserPassword } = useUpdateUserPassword();
  const { id, username } = userData;
  const [isOpenUpdatePassword, setIsOpenUpdatePassword] = useState(false);

  const handleContinue = async () => {
    await updateUserPassword({ id });
    setIsOpenUpdatePassword(true);
    toast.success(tUpdateUserPasswordModal('successMessage'));
  };

  const handleCloseUpdatePasswordModal = () => {
    setIsOpenUpdatePassword(false);
    onClose();
  };

  return (
    <>
      <Modal onClose={onClose} isHidden={isOpenUpdatePassword}>
        <div className={style.content}>
          <div className={style.description}>{t('title', { username })}</div>
          <div className={style.buttons}>
            <Button
              classNames={{ button: style.button }}
              label={t('update')}
              disabled={isMutating}
              onClick={handleContinue}
            />
            <Button
              classNames={{ button: cn(style.button, style.button__red) }}
              label={t('cancel')}
              disabled={isMutating}
              onClick={onClose}
            />
          </div>
        </div>
      </Modal>
      {isOpenUpdatePassword && newUserData && (
        <UpdateUserPasswordModal userData={newUserData} onClose={handleCloseUpdatePasswordModal} />
      )}
    </>
  );
};
