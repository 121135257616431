import { fetcher, Method, MEETINGS } from 'api';
import useSWR from 'swr';
import { Meeting } from 'types';

type GetMeetingParams = {
  id?: number | string;
};

type GetMeetingResponse = Meeting;

const getMeeting = async (path: string) => fetcher<GetMeetingResponse>({ path, method: Method.GET });

export const useGetMeeting = (params: GetMeetingParams) => useSWR(`${MEETINGS}${params.id}/`, getMeeting);
