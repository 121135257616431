import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useCreateHeadset } from 'api';
import { Modal, Button, Input, DropdownSelect } from 'components/ui-kit';
import { HeadsetVersion } from 'constants/constants';
import style from './add-headset-modal.module.scss';
import { DATA_LIST } from './constants';

type AddHeadsetModalProps = {
  onClose: () => void;
};

export const AddHeadsetModal: FC<AddHeadsetModalProps> = ({ onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.add-headset-modal' });
  const { trigger: createHeadset } = useCreateHeadset();
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [version, setVersion] = useState<HeadsetVersion | null>(null);
  const [key, setKey] = useState('');

  const handleAddHeadset = async () => {
    if (!version) return;

    try {
      const body = { name, uid: id, version, key };
      await createHeadset(body);
      onClose();
      toast.success(t('successMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const isDisabled = !(name && id && version && key);

  return (
    <Modal title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <Input
          classNames={{ block: style.item }}
          label={t('nameLabel')}
          placeholder={t('namePlaceholder')}
          value={name}
          onChange={setName}
        />
        <Input
          classNames={{ block: style.item }}
          label='ID'
          placeholder={t('idPlaceholder')}
          value={id}
          onChange={setId}
        />
        <DropdownSelect
          classNames={{ block: style.item }}
          lable={t('versionLabel')}
          placeholder={t('versionPlaceholder')}
          dataList={DATA_LIST}
          value={version}
          onChange={setVersion}
        />
        <Input
          classNames={{ block: style.item }}
          label={t('keyLabel')}
          placeholder={t('keyPlaceholder')}
          value={key}
          onChange={setKey}
        />
        <div className={style.buttons}>
          <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
          <Button
            classNames={{ button: style.button }}
            label={t('add')}
            isDisabled={isDisabled}
            onClick={handleAddHeadset}
          />
        </div>
      </div>
    </Modal>
  );
};
