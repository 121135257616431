import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineAim } from 'react-icons/ai';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { Tooltip } from 'components/ui-kit';
import style from './controls.module.scss';

type LaserProps = {
  socket: Socket | null;
};

export const Laser: FC<LaserProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isLaser, setIsLaser] = useState(false);

  useEffect(() => {
    if (!socket) return;
    const updateState = (value: boolean) => {
      setIsLaser(value);
    };
    socket.on('laser_state', updateState);
    socket.on('laser_set', updateState);

    return () => {
      socket.off('laser_state', updateState);
      socket.off('laser_set', updateState);
    };
  }, [socket]);

  const handleToggle = () => {
    socket?.emit('laser_set', !isLaser);
    setIsLaser(!isLaser);
  };

  return (
    <>
      <Tooltip id='controls.laser' content={t('controls.laser', { status: isLaser ? t('off') : t('on') })} />
      <button
        className={cn(style.button, { [style.button__off]: !isLaser })}
        data-tooltip-id='controls.laser'
        onClick={handleToggle}
      >
        <AiOutlineAim size='20px' color='black' />
      </button>
    </>
  );
};
