import React, { FC, useState } from 'react';
import { Socket } from 'socket.io-client';
import { SnapshotNotification } from 'components/notifications';

type SnapshotTakenProps = {
  socket: Socket | null;
};

export const SnapshotTaken: FC<SnapshotTakenProps> = ({ socket }) => {
  const [src, setSrc] = useState('');
  const [href, setHref] = useState('');

  socket?.on('send_snapshot', data => {
    const src = 'data:image/jpeg;base64,' + data;
    setSrc(src);
  });

  socket?.on('send_snapshot_url', data => {
    setHref(process.env.REACT_APP_API_URL + data);
  });

  const handleCloseNotification = () => setSrc('');

  return <>{src && <SnapshotNotification src={src} href={href} onClose={handleCloseNotification} />}</>;
};
