import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'components/ui-kit';
import style from './photo-modal.module.scss';

type PhotoModalProps = {
  url: string;
  alt?: string;
  onClose: () => void;
};

export const PhotoModal: FC<PhotoModalProps> = ({ url, alt, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.photo-modal' });

  return (
    <Modal classNames={{ modal: style.modal }} onClose={onClose}>
      <div className={style.content}>
        <img className={style.img} alt={alt ?? ''} src={url} />
        <Button classNames={{ button: style.button }} label={t('close')} onClick={onClose} />
      </div>
    </Modal>
  );
};
