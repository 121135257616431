import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLogin } from 'api';
import { ActionsIsAuthContext } from 'contexts';
import { AuthHeader } from 'components/headers';
import { Input, Button } from 'components/ui-kit';
import { setAuthorizationToken } from 'utils';
import { setUserRole } from 'utils/user-role';
import { PRIVATE_CABINET, PASSWORD_RESET, AUTH } from 'constants/path';
import style from './login.module.scss';

export const Login = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'login' });
  const navigate = useNavigate();
  const { trigger } = useLogin();
  const { setIsAuth } = useContext(ActionsIsAuthContext);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    try {
      const body = { username: login, password };
      const { token, user } = await trigger(body);

      setAuthorizationToken(token);
      setUserRole(user.role);
      setIsAuth(true);
      //TODO: delete
      navigate(PRIVATE_CABINET);
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handlePasswordReset = () => {
    navigate(PASSWORD_RESET);
  };

  const handleBack = () => {
    navigate(AUTH);
  };

  const isDisabled = !(login && password);

  return (
    <>
      <AuthHeader title={t('title')} onBack={handleBack} />
      <form onSubmit={handleSubmit}>
        <Input
          classNames={{ block: style.input }}
          type='text'
          label={t('loginLabel')}
          placeholder={t('loginPlaceholder')}
          value={login}
          onChange={setLogin}
        />
        <Input
          classNames={{ block: style.input }}
          type='password'
          label={t('passwordLabel')}
          placeholder={t('passwordPlaceholder')}
          value={password}
          onChange={setPassword}
        />
        <Button classNames={{ button: style.button }} isDisabled={isDisabled} label={t('loginButton')} />
        <Button
          classNames={{ button: style.buttonPasswordReset }}
          type='button'
          label={t('resetPasswordButton')}
          onClick={handlePasswordReset}
        />
      </form>
    </>
  );
};
