import { fetcher, Method, HEADSET } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { HeadsetVersion } from 'constants/constants';
import { Headset } from 'types';
import { GetHeadsetsResponse } from './get-headsets';

export type UpdateHeadsetParams = {
  id: string;
  body: { name: string; uid: string; version: HeadsetVersion; key: string };
};

export type UpdateHeadsetResponse = Headset;

const updateHeadset = async (path: string, { arg: { id, body } }: { arg: UpdateHeadsetParams }) =>
  fetcher<UpdateHeadsetResponse>({ path: `${path}${id}/`, method: Method.PUT, body });

export const useUpdateHeadset = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(HEADSET, updateHeadset, {
    onSuccess: (/* updatedHeadset */) => {
      mutate<GetHeadsetsResponse[]>(
        key => typeof key === 'string' && key.startsWith(HEADSET),
        /* , data => {
          console.log(data);
          // Если данные связаны с useSWRInfinite, обновляем массив страниц
          if (Array.isArray(data)) {
            return data.map(page => ({
              ...page,
              results: page.results.map(headset => (headset.id === updatedHeadset.id ? updatedHeadset : headset)),
            }));
          }
          return data;
        }, */
      );
    },
  });
};
