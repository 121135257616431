import { LOCAL_STORAGE_AUTHORIZATION_TOKEN } from 'constants/local-storage';

export const getAuthorizationToken = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTHORIZATION_TOKEN);
  return token;
};

export const setAuthorizationToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_AUTHORIZATION_TOKEN, token);
};

export const removeAuthorizationToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_AUTHORIZATION_TOKEN);
};
