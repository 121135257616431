import React, { FC, ReactNode } from 'react';
import { Button } from 'components/ui-kit';
import style from './column.module.scss';

type ColumnProps = {
  children: ReactNode;
  title: string;
  buttonLabel?: string;
  isDisabled?: boolean;
  isHiddenButton?: boolean;
  columnRef?: React.RefObject<HTMLDivElement>;
  onClick?: () => void;
};

export const Column: FC<ColumnProps> = ({
  children,
  title,
  buttonLabel,
  isDisabled,
  isHiddenButton,
  columnRef,
  onClick,
}) => {
  return (
    <div className={style.column}>
      <div className={style.header}>
        {!isHiddenButton && onClick && (
          <Button classNames={{ button: style.button }} label={buttonLabel} isDisabled={isDisabled} onClick={onClick} />
        )}
        <div className={style.headerTitle}>{title}</div>
      </div>
      <div className={style.content} ref={columnRef}>
        <div>{children}</div>
      </div>
    </div>
  );
};
