import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { useGetMe } from 'api/endpoints/user';
import { IsVisibleHeaderContext } from 'contexts/is-visible-header-context';
//@ts-ignore
import Chatbox from 'https://cdn.jsdelivr.net/npm/@chaindesk/embeds@latest/dist/chatbox/index.js';
import { useGetUserRole } from 'hooks/use-get-user-role';
import { useGetWindowSize } from 'hooks/use-get-window-size';
import { DEMO_USER_ID } from 'constants/constants';
//'https://gcore.jsdelivr.net/npm/@chaindesk/embeds@latest/dist/chatbox/index.js';
//'https://cdn.jsdelivr.net/npm/@chaindesk/embeds@latest/dist/chatbox/index.js';
import { Header, CheckListControl } from './';
import style from './private-cabinet-layout.module.scss';

export const PrivateCabinetLayout = () => {
  useGetUserRole();
  const { isVisible } = useContext(IsVisibleHeaderContext);
  const { data: user } = useGetMe();
  const { isMobile } = useGetWindowSize();
  const isDemoUser = user && user.id === DEMO_USER_ID;

  useEffect(() => {
    if (!isDemoUser || process.env.NODE_ENV === 'development' || isMobile) return;

    let chaindeskElement: HTMLElement;
    const asyncEffect = async () => {
      chaindeskElement = document.createElement('chaindesk-chatbox-standard');
      document.body.appendChild(chaindeskElement);
      await Chatbox.initBubble({
        agentId: 'clz1evn4x00hy2565uop2xkji',
      });
    };
    asyncEffect();

    return () => {
      document.body.removeChild(chaindeskElement);
    };
  }, [isDemoUser, isMobile]);

  return (
    <>
      {/* TODO: добавить лоудер */}
      <div className={style.page}>
        {isVisible && <Header />}
        <div className={cn(style.content, { [style.content__hasHeade]: isVisible })}>
          <Outlet />
        </div>
      </div>
      {isDemoUser && <CheckListControl />}
    </>
  );
};
