import { fetcher, Method, MEETINGS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Meeting, MeetingStatus } from 'types';

type UpdateStatusParams = {
  id: string | number;
  body: {
    status: MeetingStatus;
  };
};

type UpdateStatusResponse = Meeting;

const updateStatus = async (path: string, { arg: { id, body } }: { arg: UpdateStatusParams }) =>
  fetcher<UpdateStatusResponse>({ path: `${path}${id}/update_status/`, method: Method.POST, body });

export const useUpdateStatus = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(MEETINGS, updateStatus, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(MEETINGS));
    },
  });
};
