import { fetcher, Method, MEETINGS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { ExpertType, Meeting, TypeChoice } from 'types';

type CreateMeetingsParams = {
  customer_provider: string;
  machine_serial: string;
  description: string;
  expert_type: ExpertType;
  time_choice: TypeChoice;
  location: string;
  planned_start_date: string | undefined;
  planned_end_date: string | undefined;
  planned_start_time: string | undefined;
  planned_end_time: string | undefined;
};

type CreateMeetingsResponse = Meeting;

const createMeetings = async (path: string, { arg }: { arg: CreateMeetingsParams }) =>
  fetcher<CreateMeetingsResponse>({ path, method: Method.POST, body: arg });

export const useCreateMeetings = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(MEETINGS, createMeetings, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(MEETINGS));
    },
  });
};
