import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetHeadset } from 'api';
import { PrivateCabinetHeader } from 'components/headers';
import { QUERY } from 'constants/path';
import { Survay, Final } from './';
import style from './finish-meeting.module.scss';

export const FinishMeeting = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'finish_meeting' });
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data } = useGetHeadset({ id: headsetId });

  //TODO: после отправки ответа, эта страница должна становиться недоступна
  //(то есть при обновлении страницы, должен прооисходить редирект на главную)
  const [isSubmit, setIsSubmit] = useState(false);
  //TODO: добавить проверку действительно ли комната завершена
  //если нет, то редиректить на страницу комнаты

  return (
    <>
      <PrivateCabinetHeader title={t('conference_end_headset', { name: data?.name })} />
      <div className={style.container}>{isSubmit ? <Final /> : <Survay setIsSubmit={setIsSubmit} />}</div>
    </>
  );
};
