import { fetcher, Method, CONNECT_TO_PROVIDER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export type ConnectToProviderParams = {
  provider_token: string;
};

export type ConnectToProviderResponse = {
  id: string;
  customer: string;
  provider: string;
  provider_token: string;
  customer_token: string;
  is_active: boolean;
  created_at: string;
};

const connectToProvider = async (path: string, { arg }: { arg: ConnectToProviderParams }) =>
  fetcher<ConnectToProviderResponse>({ path, method: Method.POST, body: arg });

export const useConnectToProvider = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(CONNECT_TO_PROVIDER, connectToProvider, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(CONNECT_TO_PROVIDER));
    },
  });
};
