import React, { FC, useEffect, useMemo, useState } from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';
import { MdOutlineSubject } from 'react-icons/md';
import cn from 'classnames';
import { PhotoModal } from 'components/modals';
import { TypeMessage } from './messages-block';
import style from './styles.module.scss';

type TFile = Omit<TypeMessage['attachments'][number], 'type'>;

type MessageProps = {
  isMyMessage: boolean;
  senderName: string;
  text: string;
  time: string;
  attachments: TypeMessage['attachments'];
  lastMessageRef?: React.LegacyRef<HTMLDivElement>;
};

export const Message: FC<MessageProps> = ({ isMyMessage, senderName, text, time, attachments, lastMessageRef }) => {
  const [photos, setPhotos] = useState<TFile[]>([]);
  const [documents, setDocuments] = useState<TFile[]>([]);
  const [selectSrc, setSelectSrc] = useState('');

  useEffect(() => {
    if (!attachments) return;

    const photos: TFile[] = [];
    const documents: TFile[] = [];

    attachments.forEach(({ name, url, type }) => {
      const obj = { name, url };

      if (type === 'image') {
        photos.push(obj);
      } else if (type === 'file') {
        documents.push(obj);
      }
    });

    setPhotos(photos);
    setDocuments(documents);
  }, [attachments]);

  const handleOpenModal = (src: string) => {
    setSelectSrc(src);
  };

  const handleCloseModal = () => {
    setSelectSrc('');
  };

  const displayPhotos = useMemo(
    () =>
      photos.map(item => {
        const { url, name } = item;
        const src = `${process.env.REACT_APP_API_URL}${url}`;

        return (
          <div key={url} className={style.photosItem}>
            <img className={style.img} alt={name} src={src} onClick={() => handleOpenModal(src)} />
          </div>
        );
      }),
    [photos],
  );

  const displayDocuments = useMemo(
    () =>
      documents.map(({ name, url }) => (
        <div key={url} className={style.ducumentsItem}>
          <div className={style.image}>
            <MdOutlineSubject color='white' />
          </div>
          <div className={style.name}>{name}</div>
          <a className={style.downloadFile} href={`${process.env.REACT_APP_API_URL}${url}`} download={name}>
            <CgSoftwareDownload color='black' size={24} />
          </a>
        </div>
      )),
    [documents],
  );

  return (
    <>
      <div
        className={cn(style.messageContainer, { [style.messageContainer__myMessage]: isMyMessage })}
        ref={lastMessageRef}
      >
        <div className={cn(style.message, { [style.message__notMy]: !isMyMessage })}>
          {!isMyMessage && <div className={style.userName}>{senderName}</div>}
          {!!photos.length && (
            <div
              className={cn(style.photosBlock, { [style.photosBlock__notContentBottom]: !text && !documents.length })}
            >
              {displayPhotos}
            </div>
          )}
          {!!documents.length && (
            <div className={cn(style.documentsBlock, { [style.documentsBlock__notText]: !text })}>
              {displayDocuments}
            </div>
          )}
          {text && <div className={style.content}>{text}</div>}
          <div className={style.time}>{time}</div>
        </div>
      </div>
      {selectSrc && <PhotoModal url={selectSrc} onClose={handleCloseModal} />}
    </>
  );
};
