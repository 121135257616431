import React from 'react';
import { Outlet } from 'react-router-dom';
import { LanguageSelector } from 'components/language-selector';
import style from './auth-layout.module.scss';

export const AuthLayout = () => {
  return (
    <div className={style.page}>
      <div className={style.content}>
        <LanguageSelector />
        <Outlet />
      </div>
      <video
        className={style.video}
        src='https://lk.avatar-tech.ru/content/static/avatar.mp4'
        muted
        autoPlay
        loop
        playsInline
      />
    </div>
  );
};
