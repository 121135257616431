import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetHeadsets } from 'api';
import { DropdownSelect } from 'components/ui-kit';
import { QUERY } from 'constants/path';
import style from './headset-selector.module.scss';

type HeadsetSelectorProps = {
  name: string;
  isActiveMeeting: boolean;
  setIsActiveMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveVideo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const HeadsetSelector: FC<HeadsetSelectorProps> = ({
  name,
  isActiveMeeting,
  setIsActiveMicrophone,
  setIsActiveVideo,
}) => {
  const [query, setQuery] = useSearchParams();
  const { data } = useGetHeadsets({});
  const [label, setLabel] = useState(name);

  const list = useMemo(() => data?.results?.map(({ id, name }) => ({ id, label: name })) ?? [], [data]);

  const queryHeadsetId = query.get(QUERY.headsetId);

  useEffect(() => {
    const headset = list.find(item => item.id === queryHeadsetId);
    if (headset) {
      setLabel(headset?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryHeadsetId]);

  const handleChange = (id: string) => {
    setQuery({ [QUERY.headsetId]: id });
    if (isActiveMeeting) {
      setIsActiveMicrophone(false);
      setIsActiveVideo(false);
    }
  };

  return (
    <div className={style.block}>
      <DropdownSelect dataList={list} value={label} onChange={handleChange} />
    </div>
  );
};
