import React from 'react';
import { useGetUserRole } from 'hooks/use-get-user-role';
import { DispatcherEditConference } from 'pages/dispatcher-edit-conference';

export const EditConference = () => {
  const role = useGetUserRole();
  return (
    <>
      {/* {role === 'admin' && <HomeAdmin />} */}
      {role === 'dispatcher' && <DispatcherEditConference />}
      {/* TODO: добавить страницу редактирования конференции у инженера  */}
    </>
  );
};
