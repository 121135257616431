import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaVideo } from 'react-icons/fa6';
import { PiWarningCircleFill } from 'react-icons/pi';
import cn from 'classnames';
import { Tooltip } from 'components/ui-kit';
import style from './controls.module.scss';

type VideoProps = {
  isActive: boolean;
  isHasAccess: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Video: FC<VideoProps> = ({ isActive, isHasAccess, setIsActive }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleVideo = () => {
    setIsActive(state => !state);
  };

  return (
    <>
      <Tooltip id='controls.video' content={t('controls.video', { status: isActive ? t('off') : t('on') })} />
      <button
        className={cn(style.button, { [style.button__off]: !isActive || !isHasAccess })}
        data-tooltip-id='controls.video'
        disabled={!isHasAccess}
        onClick={handleToggleVideo}
      >
        {!isHasAccess && (
          <>
            <Tooltip id='controls.video' content='Камера не доступна' />
            <PiWarningCircleFill
              className={style.warning}
              color='#facc15'
              size='20px'
              data-tooltip-id='controls.video'
            />
          </>
        )}
        <FaVideo size='20px' color='black' />
      </button>
    </>
  );
};
