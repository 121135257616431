import { Method, MY_CUSTOMERS, fetcher } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

type GetMyCustomersParams = {
  page?: number;
  page_size?: number;
};

export type GetMyCustomersResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: string;
    customer: string;
    provider: string;
    customer_details: {
      id: string;
      role: string;
      username: string;
      parent: string;
      email: string;
      company_name: string;
      phone: string;
      is_active: boolean;
      is_verify: boolean;
      headset: string;
    };
    provider_details: {
      id: string;
      role: string;
      username: string;
      parent: string;
      email: string;
      company_name: string;
      phone: string;
      is_active: boolean;
      is_verify: boolean;
      headset: string;
    };
    is_active: boolean;
  }[];
};

const getMyCustomers = (path: string) => fetcher<GetMyCustomersResponse>({ path, method: Method.GET });

export const useGetMyCustomers = (params: GetMyCustomersParams) => {
  //@ts-ignore
  const query = new URLSearchParams(params);
  return useSWR(`${MY_CUSTOMERS}?${query}`, getMyCustomers);
};

export const useInfinityGetMyCustomers = (params: Omit<GetMyCustomersParams, 'page'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const page = pageIndex + 1;
    //@ts-ignore
    const query = new URLSearchParams({ ...params, page });
    return `${MY_CUSTOMERS}?${query}`;
  }, getMyCustomers);
