import { fetcher, Method, MEETINGS } from 'api';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { Meeting } from 'types';

type GetMeetingsParams =
  | {
      page: number;
      page_size: number;
    }
  | unknown;

type GetMeetingsResponse = {
  count: number;
  next: string;
  previous: string;
  results: Meeting[];
};

//import qs from 'query-string';
// const queryString = qs.stringify(params || {}, { skipNull: true, skipEmptyString: true });

const getMeetings = async (path: string) => fetcher<GetMeetingsResponse>({ path, method: Method.GET });

export const useGetMeetings = (params: GetMeetingsParams) => useSWR(MEETINGS, getMeetings);

export const useInfinityGetMeetings = (params: Omit<GetMeetingsParams, 'page'>) =>
  useSWRInfinite((pageIndex, previousPageData) => {
    const page = pageIndex + 1;
    //@ts-ignore
    const query = new URLSearchParams({ ...params, page });
    return `${MEETINGS}?${query}`;
  }, getMeetings);
