import { fetcher, Method, MEETINGS } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Meeting } from 'types';

type UpdateDatetimeParams = {
  id: string | number;
  body: {
    meeting_date: string;
    meeting_start_time: string;
    meeting_end_time: string;
  };
};

type UpdateDatetimeResponse = Meeting;

const updateDatetime = async (path: string, { arg: { id, body } }: { arg: UpdateDatetimeParams }) =>
  fetcher<UpdateDatetimeResponse>({ path: `${path}${id}/update_meeting_datetime/`, method: Method.POST, body });

export const useUpdateDatetime = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(MEETINGS, updateDatetime, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(MEETINGS));
    },
  });
};
