import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useInfinityGetHeadsets, useUpdateHeadset } from 'api';
import { DeleteHeadsetModal } from 'components/modals';
import { Modal, Button, Input, DropdownSelect } from 'components/ui-kit';
import { HeadsetVersion } from 'constants/constants';
import { Headset } from 'types';
import style from './change-headset-modal.module.scss';
import { DATA_LIST } from './constants';

type ChangeHeadsetModalProps = {
  headsetData: Headset;
  onClose: () => void;
};

export const ChangeHeadsetModal: FC<ChangeHeadsetModalProps> = ({ headsetData, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.change-headset-modal' });
  const { trigger: updateHeadset } = useUpdateHeadset();
  const { name: initialName, uid: initialUid, version: initialVersion, key: initialKey, id: headsetId } = headsetData;
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [name, setName] = useState(initialName);
  const [id, setId] = useState(initialUid);
  const [version, setVersion] = useState<HeadsetVersion | null>(initialVersion);
  const [key, setKey] = useState(initialKey);

  const { setSize } = useInfinityGetHeadsets({ limit: 20 });

  const handleChangeHeadset = async () => {
    if (!version) return;

    try {
      const body = { name, uid: id, version, key };
      await updateHeadset({ id: headsetId, body });
      onClose();
      setSize(size => size);
      toast.success(t('successMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handleOpenDeleteModal = () => setIsOpenDeleteModal(true);

  const isDisabled = !(name && id && version && key);

  return (
    <>
      <Modal title={t('title')} isHidden={isOpenDeleteModal} onClose={onClose}>
        <div className={style.content}>
          <Input
            classNames={{ block: style.item }}
            label={t('nameLabel')}
            placeholder={t('namePlaceholder')}
            value={name}
            onChange={setName}
          />
          <Input
            classNames={{ block: style.item }}
            label='ID'
            placeholder={t('idPlaceholder')}
            value={id}
            onChange={setId}
          />
          <DropdownSelect
            classNames={{ block: style.item }}
            lable={t('versionLabel')}
            placeholder={t('versionPlaceholder')}
            dataList={DATA_LIST}
            value={version}
            onChange={setVersion}
          />
          <Input
            classNames={{ block: style.item }}
            label={t('keyLabel')}
            placeholder={t('keyPlaceholder')}
            value={key}
            onChange={setKey}
          />
          <div className={style.buttons}>
            <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
            <Button
              classNames={{ button: style.button }}
              label={t('change')}
              isDisabled={isDisabled}
              onClick={handleChangeHeadset}
            />
          </div>
          <Button
            classNames={{ button: cn(style.button, style.button__delete) }}
            label={t('delete')}
            onClick={handleOpenDeleteModal}
          />
        </div>
      </Modal>
      {isOpenDeleteModal && <DeleteHeadsetModal headsetData={headsetData} onClose={onClose} />}
    </>
  );
};
