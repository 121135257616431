import { toast } from 'react-toastify';
import { getAuthorizationToken, removeAuthorizationToken } from 'utils';
import { removeUserRole } from 'utils/user-role';
import { LOCAL_STORAGE_ACCEPT_LANGUAGE } from 'constants/local-storage';
import { Method } from './constants';

type FetcherParams = {
  method: Method;
  path: string;
  body?: any;
  formData?: FormData;
  headers?: Record<string, string>;
};

export const fetcher = async <T>({ method, path, body, formData, headers }: FetcherParams): Promise<T> => {
  const authorizationToken = getAuthorizationToken();
  const acceptLanguage = localStorage.getItem(LOCAL_STORAGE_ACCEPT_LANGUAGE) ?? 'ru';

  const options: RequestInit = {
    method,
    headers: {
      Authorization: authorizationToken ? `Token ${authorizationToken}` : '',
      'Accept-Language': acceptLanguage,
      ...(formData ? {} : { 'Content-Type': 'application/json;charset=utf-8' }),
    },
  };

  if (body) options.body = JSON.stringify(body);
  if (formData) options.body = formData;
  if (headers) options.headers = { ...options.headers, ...headers };

  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, options);
  const { status } = response;

  if (status === 204) {
    return undefined as T;
  }

  const data: T = await response.json();

  if (status >= 400) {
    const errorMessage = (data as { message: string }).message;
    toast.error(`${errorMessage}`);
    console.error(data);

    if (status === 401) {
      removeAuthorizationToken();
      removeUserRole();
      //TODO: придумать более красивое решение, без перезагрузки проекта

      //добавить квери в урл а в контексте ждать его
      const { protocol, host } = window.location;
      window.location.href = `${protocol}//${host}`;
      //
    }

    throw new Error(errorMessage);
  }

  return data;
};
