import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'components/ui-kit';
import style from './new-user-information-modal.module.scss';

type NewUserInformationModalProps = {
  login: string;
  password: string;
  onClose: () => void;
};

export const NewUserInformationModal: FC<NewUserInformationModalProps> = ({ login, password, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.new-user-information-modal' });

  return (
    <Modal title={t('title')} onClose={onClose}>
      <div className={style.content}>
        <div className={style.item}>
          <div>{t('login')}</div>
          <div>{login}</div>
        </div>
        <div className={style.item}>
          <div>{t('password')}</div>
          <div>{password}</div>
        </div>
        <Button classNames={{ button: style.button }} label={t('close')} onClick={onClose} />
      </div>
    </Modal>
  );
};
