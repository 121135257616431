import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownSelect } from 'components/ui-kit';
import style from './select-devices.module.scss';

type DeviceKeys = 'audioinput' | 'videoinput';

type Devices = Record<DeviceKeys, MediaDeviceInfo[]>;

type SelectDevicesProps = {
  videoDeviceId: string;
  audioDeviceId: string;
  setVideoDeviceId: React.Dispatch<React.SetStateAction<string>>;
  setAudioDeviceId: React.Dispatch<React.SetStateAction<string>>;
};

export const SelectDevices: FC<SelectDevicesProps> = ({
  videoDeviceId,
  audioDeviceId,
  setVideoDeviceId,
  setAudioDeviceId,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [devices, setDevices] = useState<Devices>({ audioinput: [], videoinput: [] });

  const handleSelect = useCallback(
    (kind: string, deviceId: string) => {
      if (kind === 'audioinput') {
        setAudioDeviceId(deviceId);
      } else if (kind === 'videoinput') {
        setVideoDeviceId(deviceId);
      }
    },
    [setAudioDeviceId, setVideoDeviceId],
  );

  useEffect(() => {
    const asyncEffect = async () => {
      if (!navigator.mediaDevices?.getUserMedia) {
        alert('navigator.mediaDevices.getUserMedia() is not supported');
      }

      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const filterDevices: Devices = { audioinput: [], videoinput: [] };

        devices.forEach(item => {
          const { kind, groupId, deviceId } = item;
          if (!(kind === 'audioinput' || kind === 'videoinput')) return;

          if (deviceId === 'default') {
            handleSelect(kind, deviceId);
          }

          const group = filterDevices[kind];
          const isFind = group.some(item => item.groupId === groupId);
          if (isFind) return;

          group.push(item);
        });

        setDevices(filterDevices);
      } catch (e) {
        console.error(e);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const display = useMemo(() => {
    const activeValue = { audioinput: audioDeviceId, videoinput: videoDeviceId };

    return Object.entries(devices).map(([key, object]) => {
      const dataList = object.map(({ deviceId, label }, index) => ({
        id: deviceId,
        label: label ?? `${key} - ${index}`,
      }));
      const necessaryItem = object.find(item => item.deviceId === activeValue[key as DeviceKeys]);
      const value = necessaryItem?.label ?? '';
      const handleChange = (id: string) => handleSelect(key, id);

      return (
        <DropdownSelect
          key={key}
          classNames={{ block: style.dropdown }}
          lable={t(key as DeviceKeys)}
          value={value}
          dataList={dataList}
          onChange={handleChange}
        />
      );
    });
  }, [t, devices, audioDeviceId, videoDeviceId, handleSelect]);

  return <div className={style.block}>{display}</div>;
};
