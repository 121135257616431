export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const LOGIN = '/api/v1/auth/login/';
export const LOGOUT = '/api/v1/auth/logout/';

export const REGISTRATION = '/api/v1/user/registration/';
export const CONFIRM_REGISTRATION = '/api/v1/user/confirm-registration/';
export const GET_ME = '/api/v1/user/me/';
export const USER = '/api/v1/user/';
export const COLLEAGUES = '/api/v1/user/colleagues/';
export const RESTORE = '/api/v1/user/restore/';
export const CONFIRM_RESTORE = '/api/v1/user/confirm-restore/';

export const HEADSET = '/api/v1/headset/';
export const HEADSET_AS_USER = '/api/v1/headset/as-user/';

export const VIDEO = '/api/v1/video/';
export const SNAPSHOT = '/api/v1/snapshot/';

export const ATTACHMENT = '/api/v1/attachment/';

export const MEETINGS = '/api/v1/meetings/';

export const CUSTOMET_TOKENS = '/api/v1/customer-tokens/generate/';
export const PROVIDER_TOKENS = '/api/v1/provider-tokens/generate/';

export const CONNECT_TO_CUSTOMER = '/api/v1/customer-provider/connect_to_customer/';
export const CONNECT_TO_PROVIDER = '/api/v1/customer-provider/connect_to_provider/';
export const MY_CUSTOMERS = '/api/v1/customer-provider/my_customers/';
export const MY_PROVIDERS = '/api/v1/customer-provider/my_providers/';
