import { fetcher, Method, CONNECT_TO_CUSTOMER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export type ConnectToCustomerParams = {
  customer_token: string;
};

export type ConnectToCustomerResponse = {
  id: string;
  customer: string;
  provider: string;
  provider_token: string;
  customer_token: string;
  is_active: boolean;
  created_at: string;
};

const connectToCustomer = async (path: string, { arg }: { arg: ConnectToCustomerParams }) =>
  fetcher<ConnectToCustomerResponse>({ path, method: Method.POST, body: arg });

export const useConnectToCustomer = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(CONNECT_TO_CUSTOMER, connectToCustomer, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(CONNECT_TO_CUSTOMER));
    },
  });
};
