import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { ArrowDown } from 'assets';
import style from './dropdown-select.module.scss';

interface DropdownSelectProps<T> {
  value: T;
  dataList: { id: T; label: string }[];
  lable?: string;
  placeholder?: string;
  isTop?: boolean;
  classNames?: { block?: string };
  onChange: (value: T) => void;
}

export const DropdownSelect = <T,>({
  value,
  dataList,
  lable,
  placeholder = '',
  isTop,
  classNames,
  onChange,
}: DropdownSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => setIsOpen(state => !state);

  const handleSelect = useCallback(
    (id: T) => {
      onChange(id);
      handleToggleOpen();
    },
    [onChange],
  );

  const displayList = useMemo(
    () =>
      dataList.map(({ id, label }, index) => (
        <div key={index} className={style.item} onClick={() => handleSelect(id)}>
          {label}
        </div>
      )),
    [dataList, handleSelect],
  );

  return (
    <div className={cn(style.block, classNames?.block)}>
      {lable && <div className={style.label}>{lable}</div>}
      <div
        className={cn(style.field, {
          [style.field__visiblePlaceholder]: !value,
          [style.field__isOpen]: isOpen && !isTop,
          [style.field__isOpenTop]: isOpen && isTop,
        })}
        onClick={handleToggleOpen}
      >
        <span>{value?.toString() || placeholder}</span>
        <ArrowDown className={cn(style.icon, { [style.icon__open]: isOpen })} width={20} height={20} />
      </div>
      {isOpen && <div className={cn(style.list, { [style.list__top]: isTop })}>{displayList}</div>}
    </div>
  );
};
