import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { RemoteStreams } from 'hooks/use-janus';
import { OtherContent } from 'pages/meeting/constants';
import { useGetVisibleCameraControls } from 'pages/meeting/use-get-is-visible-camera-controls';
import { Messenger } from 'components/messenger';
import { MainStream, AllStreams } from './';
import style from './content.module.scss';

type ContentProps = {
  socket: Socket | null;
  userName: string;
  stream: MediaStream | null;
  remoteStreams: RemoteStreams;
  typeOtherContent: OtherContent | null;
  isFullscreen: boolean;
  headsetId: string | null;
  isShowVisualEffects: boolean;
  isTranslation: boolean;
  selectStreamId: string;
  setSelectStreamId: React.Dispatch<React.SetStateAction<string>>;
};

export const Content: FC<ContentProps> = ({
  socket,
  userName,
  stream,
  remoteStreams,
  typeOtherContent,
  isFullscreen,
  headsetId,
  isShowVisualEffects,
  isTranslation,
  selectStreamId,
  setSelectStreamId,
}) => {
  const [isSwitchComplete, setIsSwitchComplete] = useState(false);
  const { isMyHeadset, headsetStream } = useGetVisibleCameraControls({
    myStream: stream,
    remoteStreams,
    selectStreamId,
  });

  const headsetStreamId = headsetStream?.stream?.id;
  const myStreamId = stream?.id;

  useEffect(() => {
    //TODO: для лучшего флоу работы автоматического переключения на стрим камеры добавить НОВЫЙ ивент в вебсокеты,
    //в котором будет приходить, что камера вошла в комнату.
    //Когда будет готов этот ивент, надо будет переписать этот эффект
    if (isSwitchComplete) return;
    if (!isMyHeadset && headsetStreamId && selectStreamId !== headsetStreamId) {
      const tiimer = setTimeout(() => {
        setSelectStreamId(headsetStreamId);
        toast.success('Совершено автоматическое переключение на камеру');
        setIsSwitchComplete(true);
      }, 300);

      return () => clearTimeout(tiimer);
    } else if (myStreamId && selectStreamId !== myStreamId) {
      setSelectStreamId(myStreamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headsetId, myStreamId, headsetStreamId, isMyHeadset]);

  useEffect(() => {
    setIsSwitchComplete(false);
  }, [headsetId]);

  return (
    <div className={style.block}>
      <div
        className={cn(style.mainStreamContainer, {
          [style.mainStreamContainer__otherBlock]: typeOtherContent,
          [style.mainStreamContainer__notOtherBlock]: !typeOtherContent,
          [style.mainStreamContainer__fullscreen]: isFullscreen,
          [style.mainStreamContainer__translation]: isTranslation,
        })}
      >
        <MainStream
          socket={socket}
          selectStreamId={selectStreamId}
          userName={userName}
          myStream={stream}
          remoteStreams={remoteStreams}
          isShowVisualEffects={isShowVisualEffects}
          isFullscreen={isFullscreen}
        />
      </div>
      <div className={cn(style.otherBlock, { [style.otherBlock__hidden]: !typeOtherContent })}>
        {typeOtherContent === OtherContent.Chat && headsetId && <Messenger chatId={headsetId} />}
        <AllStreams
          isVisivle={typeOtherContent === OtherContent.People}
          userName={userName}
          myStream={stream}
          remoteStreams={remoteStreams}
          selectStreamId={selectStreamId}
          setSelectStreamId={setSelectStreamId}
        />
      </div>
    </div>
  );
};
