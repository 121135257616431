import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfinityGetUsers, useGetMe } from 'api';
import { useIntersection } from 'hooks';
import { Column } from 'components/column';
import { AddUserModal } from 'components/modals';
import { User } from 'types';
import { UsersColumnItem } from './';

const LIMIT = 20;

export const UsersColumn = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'private_cabinet' });
  const { data: user } = useGetMe();
  const { data: usersData, setSize } = useInfinityGetUsers({ limit: LIMIT });
  const [users, setUsers] = useState<User[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const columnRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersection(lastItemRef, { root: columnRef.current });

  useEffect(() => {
    if (!isVisible) return;
    setSize(state => ++state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (!usersData) return;

    const users: User[] = [];
    usersData.forEach(item => {
      const { results } = item;
      users.push(...results);
    });
    setUsers(users);
  }, [usersData]);

  const handleAddUser = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  const displayList = useMemo(
    () =>
      users.map(({ id, ...otherData }, index) => (
        <UsersColumnItem
          key={id}
          columnItemRef={index === users.length - 1 ? lastItemRef : undefined}
          id={id}
          {...otherData}
        />
      )),
    [users],
  );

  return (
    <>
      <Column
        title={t('usersTitle')}
        buttonLabel={t('addUsersButton')}
        columnRef={columnRef}
        isDisabled={!user?.is_verify}
        onClick={handleAddUser}
      >
        {displayList}
      </Column>
      {isOpen && <AddUserModal onClose={handleCloseModal} />}
    </>
  );
};
